import Axios from "axios";

export const defaultConfig = {
  baseURL: "/spiderman",
};

const _axios = Axios.create(defaultConfig);

_axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);


export function onResponseSuccess(response){
  return response.data
}

_axios.interceptors.response.use(
  onResponseSuccess,
  function (error) {
    return Promise.reject(error);
  }
);

export default _axios;
