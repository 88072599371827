<template>
  <div class="page">
     <div class="page__title">价格监控订阅</div>
      <div class="form">
        <!-- <div class="form-item">
           <div class="form-item__label">平台：</div>
           <div class="form-item__content">
             <span>飞猪</span>
           </div>
        </div>
        <div class="form-item">
           <div class="form-item__label">OTA：</div>
           <div class="form-item__content">
             <span>飞猪</span>
             <span>同城</span>
             <span>途牛</span>
           </div>
        </div> -->
        <div class="form-item">
           <div class="form-item__content">
             <van-button type="primary" size="small" @click="onClickShow"> 
                <van-icon name="plus" />
                新增监控
             </van-button>
           </div>
        </div>
        <div class=" text-lg mb-10px">监控记录</div>
        <div class="tables">
          <div class="table">
            <ul class="table-column-group">
              <li class="table-column w-13" />
              <li class="table-column w-26" />
              <li class="table-column w-26" />
              <li class="table-column w-26" />
              <li class="table-column w-26" />
              <li class="table-column w-26" />
              <li class="table-column w-26" />
              <li class="table-column w-26" />
              <li class="table-column w-46" />
            </ul>
            <div class="table-header-group">
              <ul class="table-row">
                <li class="table-cell text-center px-2">序号</li>
                <li class="table-cell text-left px-2">店铺名称</li>
                <li class="table-cell text-left px-2">船名</li>
                <li class="table-cell text-center px-2">航次</li>
                <li class="table-cell text-center px-2">套餐名称</li>
                <li class="table-cell text-center px-2">报警价格</li>
                <li class="table-cell text-center px-2">监控频率</li>
                <li class="table-cell text-center px-2">状态</li>
                <li class="table-cell text-center px-2">操作</li>
              </ul>
            </div>
          </div>
          <div class="table">
            <div class="table-row-group" v-for="(item,nn) in monitorList" :key="nn">
              <div class="table-row text-center">
                <div class="table-cell w-13 border-b py-2 px-2 align-middle text-center"><p class="leading-5">{{item.id}}</p></div>
                <div class="table-cell w-26 border-b py-2 px-2 align-middle text-left"><p class="leading-5">{{item.shop_name}}</p></div>
                <div class="table-cell w-26 border-b py-2 px-2 align-middle text-left"><p class="leading-5">{{item.ship_name}}</p></div>
                <div class="table-cell w-26 border-b py-2 px-2 align-middle">
                  <p v-for="(row,rIndex) in item.dates" :key="rIndex" class="leading-5">{{row}}</p>
                </div>
                <div class="table-cell w-26 border-b py-2 px-2 align-middle">{{item.crowd}}/{{item.package}}</div>
                <div class="table-cell w-26 border-b py-2 px-2 align-middle">￥{{toMoney(item.limit_price)}}</div>
                <div class="table-cell w-26 border-b py-2 px-2 align-middle">{{item.frequency}}小时</div>
                <div class="table-cell w-26 border-b py-2 px-2 align-middle">
                  <van-tag plain :type="item.status===1?'success':'warning'" size="medium" >{{item.status_label}}</van-tag>
                </div>
                <div class="table-cell w-46 border-b align-middle">
                  <van-button type="warning" class="mr-20" size="small" v-if="item.status===1" @click="onPauseStatus(item)">暂停</van-button>
                  <van-button type="success" class="mr-20" size="small" v-if="item.status===2" @click="onOpenStatus(item)">开启</van-button>
                  <van-button type="primary" class="mr-20" size="small" @click="onEditInfo(item)">修改</van-button>
                  <van-button type="danger" size="small" @click="deleteFiggyProduct(item)">删除</van-button>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>

    <van-overlay :show="show" :lock-scroll="false" bind:click="onClickHide">
      <view class="wrapper">
       <van-form @submit="onSave" showError>
         <van-cell-group class="wrapper__content">
            <van-field
              v-model="formData.url"
              name="url"
              clearable
              label="店铺链接"
              label-width="60px"
              placeholder="请输入店铺链接"
              :disabled="!!formData.id"
            >
              <template #button>
                <van-button slot="button" size="small" type="primary" v-if="!formData.id" @click="onInputUrl">获取店铺信息</van-button>
              </template>
            </van-field>
            <van-field
              v-model="formData.shop_name"
              name="shop_name"
              clearable
              label="店铺名称"
              label-width="60px"
              readonly
            />
            <van-field
              v-model="formData.ship_name"
              name="ship_name"
              clearable
              label="船名"
              label-width="60px"
              readonly
            />

            <div class="package van-cell">
                <div class="package__label van-cell__title van-field__label" style="width:60px">人群类型</div>
                <div class="package_content">
                    <van-tag plain :type="formData.crowd===row.id?'warning':'primary'" size="medium" v-for="(row,cIndex) in  crowdList" :key="cIndex" @click="onSelectCrowd(row)">{{row.value}}</van-tag>
                </div>
            </div>

            <div class="package van-cell">
                <div class="package__label van-cell__title van-field__label"  style="width:60px">套餐</div>
                <div class="package_content">
                    <van-tag plain :type="formData.package ===row.id?'warning':'primary'" size="medium" v-for="(row,pIndex) in  packageList" :key="pIndex" @click="onSelectPackage(row)">{{row.value}}</van-tag>
                </div>
            </div>
            <div class="package van-cell">
                <div class="package__label van-cell__title van-field__label"  style="width:60px">可选航次<br/>(可多选)</div>
                <div class="package_content">
                  <van-tag plain :type="formData.dates.includes(row)?'warning':'primary'" size="medium" v-for="(row,dIndex) in  datesList" :key="dIndex" @click="onSelectVoyageDate(row)">{{row}}</van-tag>
                </div>
            </div>
            <van-field
              v-model="formData.limit_price"
              name="limit_price"
              type="number"
              clearable
              label="价格阈值"
              label-width="60px"
            />
            <div class="package van-cell">
                <div class="package__label van-cell__title van-field__label"  style="width:60px">监控频率</div>
                <div class="package_content">
                  <van-stepper 
                    v-model="formData.frequency"
                    name="frequency"
                    step="0.5" 
                    :decimal-length="1"
                    :min="0.1"
                    :max="24"
                   />
                  <span class="ml-20px">小时</span>
                </div>
            </div>
            <div class="text-center mt-20px">
                <van-button type="default" size="small" class="w-140px text-center mr-20"  @click="onClickHide">取消</van-button>
                <van-button type="primary" size="small" class="w-140px text-center" native-type="submit">确定订阅</van-button>
            </div>
        </van-cell-group>
      </van-form>
      </view>
    </van-overlay>

  </div>
</template>
<script>
import { getFliggyList,deleteFiggy,getMonitorInfo,getStoreProduct,setMonitorBase,setPauseStatus,setOpenStatus} from "@/api/figgy";
import { Toast,Stepper,Checkbox, CheckboxGroup,Form,Button,Dialog } from "vant";

function createFun(){
  return {
    id:0,
    url:"",
    product_id:"",//产品ID
    shop_name:"",//店铺名称
    ship_name:"",//船名
    crowd:"",//人群类型
    package:"",//套餐
    package_name:"",//套餐名称
    dates:[],//航次
    limit_price:0,//价格阈值
    frequency:0.5,//频率
  }
}

export default {
  data() {
    return {
      show:false,
      formData:createFun(),
      monitorList:[],//监控列表
      crowdList:[],//人群类型
      packageList:[],//套餐列表
      dates:[],//所有日期团期
      datesList:[],//航次列表
    };
  },
  components: {
    Toast,Stepper,Checkbox, CheckboxGroup,Form,Button,Dialog
  },
  mounted(){
    this.getList();
  },
  methods: {
    async getList(){
      let {code,data} = await getFliggyList();
      if(code !=10000){
        return false;
      }
      this.monitorList = data;
    },
    //新增打开监控弹框
    onClickShow(){
      this.show =  !this.show;
      Object.assign(this.formData,createFun());
      this.crowdList = [];//人群类型
      this.packageList = [];//套餐列表
      this.dates = [];//所有日期团期
      this.datesList = [];//航次列表
    },
    //关闭监控弹窗
    onClickHide(){
      this.show =  !this.show;
    },
    toMoney(num){
      return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
    },
    //删除监控产品
    async deleteFiggyProduct(row){
      let result = await Dialog.confirm({
        title: '提示',
        message:
          '您确定要删除吗',
      });
      if(result ==='confirm'){
        let {code,msg} = await deleteFiggy({id:row.id});
        if(code !=10000){
          return false;
        }
        Toast.success(msg);
        this.getList();
      }
    },
    //修改编辑信息
    async onEditInfo(item){
      let {code,data} = await getMonitorInfo({id:item.id});
      if(code !=10000){
        return false;
      }
      data.dates = data.dates.split(',');
      Object.assign(this.formData,data);
      await this.getProduct(data.url);
      this.show = true;
    },
    //新增编辑监控信息
    async onSave(){
      this.formData.frequency   = Number(this.formData.frequency);
      this.formData.limit_price = Number(this.formData.limit_price);
      if(!this.formData.url){
        Toast.fail('请输入url');
        return false;
      }
      if(!this.formData.crowd){
        Toast.fail('请选择人群类型');
        return false;
      }
      if(!this.formData.package){
        Toast.fail('请选择套餐');
        return false;
      }
      if(!this.formData.dates.length){
        Toast.fail('请选择航次');
        return false;
      }
      if(!this.formData.limit_price||this.formData.limit_price < 0){
        Toast.fail('请输入价格阈值');
        return false;
      }

      let {code,msg} = await setMonitorBase(this.formData);
      if(code !=10000){
        Toast.fail(msg);
        return false;
      }
      Toast.success(msg);
      this.show = false;
      this.getList();
    },
    async onInputUrl(){
      if(!this.formData.url){
        return;
      }
      await this.getProduct(this.formData.url);
    },
    //根据URL获取套餐内容
    async getProduct(url){
      let {code,data} = await getStoreProduct({url});
      if(code !=10000){
        return false;
      }
      let {crowd,package:package1,dates,shop_name,ship_name,product_id} = data;
      this.crowdList = crowd;
      this.packageList = package1;
      this.dates       = dates;
      Object.assign(this.formData,{shop_name,ship_name,product_id});

      let key = this.formData.crowd+';'+this.formData.package;
      this.datesList = this.dates[key];
    },
    //选中人群字段
    onSelectCrowd(row){
      this.formData.crowd = row.id;
      this.formData.crowd_label = row.value;
      this.formData.package      = '';
      this.formData.package_name = '';
      this.formData.dates        = []; 
      if(!!this.formData.crowd&&!!this.formData.package){
        this.onSelectVoyage();
      }
    },
    //选中套餐
    onSelectPackage(row){
      this.formData.package = row.id;
      this.formData.package_name = row.value;
      if(!!this.formData.crowd&&!!this.formData.package){
        this.onSelectVoyage();
      }
    },
    //初始化航次数据
    onSelectVoyage(){
      let key = this.formData.crowd+';'+this.formData.package;
      this.datesList = this.dates[key];
      this.formData.dates = [];
    },
    //选择航次
    onSelectVoyageDate(value){
      let index = this.formData.dates.findIndex(item=>item ===value);
      if(index >=0){
        this.formData.dates.splice(index,1);
      }else{
        this.formData.dates.push(value);
      }
    },
    //暂停监控
    async onPauseStatus(row){
      let {code,msg} = await setPauseStatus({id:row.id});
      if(code !=10000){
        Toast.fail(msg);
        return false;
      }
      Toast.success(msg);
      this.getList();
    },
    //开启监控
    async onOpenStatus(row){
      let {code,msg} = await setOpenStatus({id:row.id});
      if(code !=10000){
        Toast.fail(msg);
        return false;
      }
      Toast.success(msg);
      this.getList();
    }

  },
};
</script>
<style lang="scss" scoped>

@import "./figgy.scss";

</style>
