import { defaultConfig } from "@/utils/httpFiggy.js";
import Axios from "axios";

const http = Axios.create(defaultConfig);

http.interceptors.response.use((response) => {
  return response.data;
});
//监控列表
export function getFliggyList() {
  return http.get("/fliggy/cruise/monitor-list");
}
//删除数据
export function deleteFiggy(data) {
  return http.post("/fliggy/cruise/monitor-del", data );
}

//获取已选中数据
export function getMonitorInfo(data){
  return http.post('/fliggy/cruise/monitor-info',data)
}
//根据url 获取套餐数据
export function getStoreProduct(data){
  return http.post('/fliggy/cruise/url-product',data)
}

export function setMonitorBase(data){
  return http.post('/fliggy/cruise/monitor-base', data)
}

//监控列表 暂停
export function setPauseStatus(data) {
  return http.get("/fliggy/cruise/monitor-pause?id="+data.id);
}
//监控列表 开启
export function setOpenStatus(data) {
  return http.get("/fliggy/cruise/monitor-open?id="+data.id);
}
